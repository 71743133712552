<template>
    <b-card no-body class="card-statistics">
        <b-card-body class="statistics-body mbm-5"> 
            <b-row>
                <b-col md="6">  
                    <h4 class="text-primary" v-if="nmKanb.id_wilayah !='0'">
                        <small>Rekap Titik Rawan </small><br>
                     {{ nmKanb.nm_wil }}
                </h4>
                <h4 class="text-primary" v-if="nmKanb.id_wilayah =='0'">
                        <small>Rekap Titik Rawan </small><br>
                    FLLAJ Provinsi Jawatengah
                </h4>
                </b-col>
                <b-col v-for="item in data" :key="item.icon" xl="6" sm="6" :class="item.customClass">
                    <b-media no-body>
                        <b-media-aside class="mr-2">
                            <b-avatar size="48" :variant="item.color">
                                <feather-icon size="24" :icon="item.icon" />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0">
                                {{ item.title }}
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                                {{ item.subtitle }}
                            </b-card-text>
                        </b-media-body>
                    </b-media>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>
    import {
        BCard,
        BCardHeader,
        BCardTitle,
        BCardText,
        BCardBody,
        BRow,
        BCol,
        BMedia,
        BMediaAside,
        BAvatar,
        BMediaBody,
    } from 'bootstrap-vue'
    import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

    export default {
        components: {
            BRow,
            BCol,
            BCard,
            BCardHeader,
            BCardTitle,
            BCardText,
            BCardBody,
            BMedia,
            BAvatar,
            BMediaAside,
            BMediaBody,
            FeatherIcon,
        },
        props: {
            nmKanb:{

            },
            data: {
                type: Array,
                default: () => [],
            },
        },
    }
</script>