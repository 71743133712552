<template>
    <div>
        <b-row class="match-height">
            <b-col md="4"> 
                <rekap :rsKabupaten=rsKabupaten @DataKabupaten=DataKabupaten></rekap>
            </b-col>
            <b-col md="8">
                <statistik :data=statisticsItems :nmKanb=nmKanb></statistik>
            </b-col>
        </b-row>
        <b-row class="match-height">
            <b-col md="4">
                <b-card class="card-congratulations bg-warning match-height" @click="act_data('')">
                    <rekap_detail :rsKategori = rsKategori  @LoadDataKategori = LoadDataKategori></rekap_detail>
                </b-card>
            </b-col>
            <b-col md="8">
                <b-card>
                    <table_data  :dataItems = dataItems @LoadDetail = LoadDetail></table_data>
                </b-card>
            </b-col>
        </b-row>
        <detail_tanggapan :rsDetail = rsDetail></detail_tanggapan>
    </div>
</template>

<script>
    import Base from '@/config/Mixins_base';
    import axios from '@/config/Axios';
    import detail_tanggapan from './component/detail_tanggapan.vue';
    import rekap from './component/rekap.vue';
    import table_data from './component/tabel.vue';
    import {
        BRow,
        BCol,
        BCard,
        BButton
    } from 'bootstrap-vue'
    import Statistik from './component/statistik.vue';
    import Rekap_detail from './component/rekap_detail.vue';
    export default {
        components: {
            BRow,
            BCol,
            BCard,
            BButton,
            rekap,
            table_data,
            Statistik,
            Rekap_detail,
            detail_tanggapan

        },
        mixins: [Base],
        data() {
            return {
                rsKabupaten: [],
                dataItems: [],
                rsKategori: [],
                nmKanb: { id_wilayah : '0'},
                jmRawan: 0,
                id_wilayah: '',
                id_kategori: '',
                rsDetail : {},
                statisticsItems: [{
                        icon: 'MapPinIcon',
                        color: 'light-danger',
                        title: '230',
                        subtitle: 'Total Titik Rawan',
                        customClass: 'mb-2 mb-xl-0',
                    },

                ],
            }
        },
        mounted() {
            this.cek_token();
            this.load_data();
            this.jm_titik_rawan();
            this.kategori_titik_rawan();
            this.kabupaten()
        },
        methods: {
            LoadDetail(value){
                this.rsDetail = value
            },
            LoadDataKategori(value){
                this.id_kategori = value.id_kategori;
                this.load_data();  
            },
            DataKabupaten(value) {
                if(value.id_wilayah =='0'){
                    this.id_wilayah = '';
                    this.id_kategori = '';
                }
                this.nmKanb = value;
                this.id_wilayah = value.id_wilayah;
                this.load_data();  
                this.jm_titik_rawan();
                this.kategori_titik_rawan();
            },
            async load_data() {
                const self = this;
                self.dataItems = [],
                await axios({
                        method: 'PUT',
                        url: '/api/titik_rawan/rekap/load_data',
                        data: {
                            id_wilayah: self.id_wilayah,
                            id_kategori: self.id_kategori
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.dataItems = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async jm_titik_rawan() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/titik_rawan/rekap/jm_titik_rawan',
                        data: {
                            id_wilayah: self.id_wilayah,
                            id_kategori: self.id_kategori
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                            self.jmRawan = response.data.result;

                            self.statisticsItems = [{
                                    icon: 'MapPinIcon',
                                    color: 'light-danger',
                                    title: response.data.result.jm,
                                    subtitle: 'Total Titik Rawan',
                                    customClass: 'mb-2 mb-xl-0',
                                }]

                            }).catch(err => {
                            self.pesan = err.message;
                            self.notification('warning', "Error", err.message);
                        });
                    },
                    async kategori_titik_rawan() {
                            const self = this;
                            await axios({
                                    method: 'PUT',
                                    url: '/api/titik_rawan/rekap/kategori_titik_rawan',
                                    data: {
                                        id_wilayah: self.id_wilayah,
                                        id_kategori: self.id_kategori
                                    },
                                    headers: {
                                        'Authorization': self.isAuthenticated
                                    }
                                })
                                .then(function (response) {
                                    self.rsKategori = response.data.result;

                                }).catch(err => {
                                    self.pesan = err.message;
                                    self.notification('warning', "Error", err.message);
                                });
                        },
                        async kabupaten() {
                            const self = this;
                            await axios({
                                    method: 'PUT',
                                    url: '/api/titik_rawan/rekap/kabupaten',
                                    data: {

                                    },
                                    headers: {
                                        'Authorization': self.isAuthenticated
                                    }
                                })
                                .then(function (response) {
                                    self.rsKabupaten = response.data.result;

                                }).catch(err => {
                                    self.pesan = err.message;
                                    self.notification('warning', "Error", err.message);
                                });
                        },
            }

        }
</script>

<style>

</style>