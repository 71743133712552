<template>
    <div>
        <!-- basic modal -->
        <b-modal id="modal-2" title="Detail Pengaduan" ok-only ok-title="Accept" hide-footer no-close-on-backdrop
            size="xl"> 
            <b-row class="match-height">
                <b-col md="6">
                    <b-row>
                        <b-col md="6">
                            <h6><small>Jenis Rawan</small> </h6>
                            <h6 class="text-warning" style="font-size: 50px;"> {{ rsDetail.kategori_titik_rawan }}</h6>
                        </b-col>
                        <b-col md="6">
                            <h6><small>Lokasi</small> </h6>
                            <h6>
                                <feather-icon icon="MapIcon" size="15"></feather-icon>  {{ rsDetail.nm_wil }}  
                            </h6>
                            <h6><small>Koordinat</small> </h6>
                            
                            <h6>
                                <a :href="'https://www.google.co.id/maps/search/'+rsDetail.koordinat"
                                        target="_blank">
                                        <feather-icon icon="MapPinIcon" size="15" /> {{ rsDetail.koordinat }}
                                    </a>
                            </h6>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="12">
                            <b-alert variant="warning" show>
                                <div class="alert-body">
                                    <h6>Judul</h6>
                                    <p class="text-primary"><small  v-html="rsDetail.deskripsi"> </small></p>
                                </div>
                            </b-alert>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="12">
                            <b-alert variant="danger" show>
                                <div class="alert-body">
                                    <h6>Deskripsi</h6>
                                    <p class="text-primary"><small  v-html="rsDetail.permasalahan">
                                        
                                        </small></p>
                                </div>
                            </b-alert>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="12">
                            <b-alert variant="primary" show>
                                <div class="alert-body">
                                    <h6>Rekomendasi Penanganan</h6>
                                    <p class="text-primary" v-html="rsDetail.penanganan"> </p>
                                </div>
                            </b-alert>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="12">
                            <iframe :src="rsDetail.vidio" width="100%" height="360"
                                frameborder="0" allowfullscreen></iframe>
                        </b-col>

                    </b-row>
                </b-col>
                <b-col md="6"  v-show="rsDetail.foto_1 !=''">
                    <b-alert variant=" " show>
                        <div class="alert-body">
                            <h4 class="text-primary"> <strong>
                                    <feather-icon icon="ImageIcon" size="25" />
                                </strong>Foto Lokasi

                            </h4>
                            <b-row>
                                <b-col md="6" class="mt-1" v-show="rsDetail.foto_1 !=''">
                                    <b-img thumbnail fluid :src="rsDetail.foto_1" />
                                    
                                </b-col>
                                <b-col md="6" class="mt-1"  v-show="rsDetail.foto_2 !=''">
                                    <b-img thumbnail fluid :src="rsDetail.foto_2" />
                                    
                                </b-col>
                                <b-col md="6" class="mt-1"  v-show="rsDetail.foto_3 !=''">
                                    <b-img thumbnail fluid :src="rsDetail.foto_3" />
                                    
                                </b-col>
                                <b-col md="6" class="mt-1"  v-show="rsDetail.foto_4 !=''">
                                    <b-img thumbnail fluid :src="rsDetail.foto_4" />
                                     
                                </b-col>
                                <b-col md="12" class="mt-1">

                                    <b-button block variant="warning" size="sm" class="mt-1" @click="close()">
                                        <feather-icon icon="XCircleIcon" size="15"></feather-icon> Keluar
                                    </b-button>
                                </b-col>
                            </b-row>
                        </div>
                    </b-alert>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
    import {
        BModal,
        BButton,
        VBModal,
        BAlert,
        BRow,
        BCol,
        BImg
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

    export default {
        data() {
            return {
                sideImg: require('@/assets/images/pages/coming-soon.svg'),
            }
        },
        components: {
            BButton,
            BModal,
            BAlert,
            BRow,
            BCol,
            BImg,
            FeatherIcon
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
        props:{
            rsDetail :{}
        },
        emits: ["simpan"],
        methods: {
            actSave() {
                this.$root.$emit('bv::hide::modal', 'modal-2');
                // this.$emit("simpan", this.rs_user, this.id_bidang)
            },
            close() {
                this.$root.$emit('bv::hide::modal', 'modal-2');
            }
        }
    }
</script>