<template>
    <div>
        <b-row>
            <b-col md="12">
                <b-card class="card-congratulations bg-danger match-height" @click="act_data('')">
                    <b-row>
                        <b-col>
                            <h5 class="text-warning">Pilih Kabupaten/Kota
                                </h5>
                            <b-form-group>
                                <v-select v-model="id_wilayah" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="bg-white"
                                    label="nm_wil" :options="rsKabupaten" @input="DataKabupaten()"/>
                            </b-form-group>
                        </b-col>
                    </b-row> 
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {
        BRow,
        BCol,
        BCard,
        BButton,
        BFormGroup
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    export default {
        components: {
            BRow,
            BCol,
            BCard,
            BButton,
            BFormGroup,
            vSelect

        },
        props : {
            rsKabupaten : {}
        },
        emits :["DataKabupaten"],
        methods : {
            DataKabupaten(){
                this.$emit("DataKabupaten", this.id_wilayah)
            }
        },
        data() {
            return { 
                id_wilayah : {}
            }
        },

    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>